<template>
  <div class="text-image">
    <h1 v-if="twigHeader" v-html="twigHeader"></h1> 
    <div class="text-content" v-if="twigText" v-html="twigText"></div>
    <img v-if="twigImages.small"
            :srcset="srcSet" 
            :sizes="imgSizes" 
            :src="twigImages.medium" 
            :alt="twigHeader" />
    <div class="caption" v-if="twigCaption">{{ twigCaption }}</div>
  </div>
</template>

<script>
export default {
  name: "TextImage",
  props: ["componentData"],
  data() {
    return {
      twigHeader: this.componentData.header,
      twigText: this.componentData.text,
      twigImages: this.componentData.images,
      twigCaption: this.componentData.caption,
      srcSet: this.componentData.images.small ? 
            this.componentData.images.small + " 479w, " 
            + this.componentData.images.small + " 640w, " 
            + this.componentData.images.medium + " 1024w," 
            + this.componentData.images.large + " 1400w, " 
            + this.componentData.images.xlarge + " 1800w" : '',
      imgSizes: this.componentData.images.small ?
            "sizes='(max-width: 479px) 479px, (max-width: 640px) 640px, (max-width: 1024px) 1024px, (max-width: 1400px) 1400px, 1800px'" : ''
    }   
  }
};
</script>

<style lang="scss" scoped>
  .left-content-container {
    .text-image {
      padding-left: 16px;
      padding-right: 16px;
      margin-bottom: 0;

      @include breakpoint-portrait-850px {
        padding-left: 20px;
        padding-right: 20px;
      }

      h1 {
          color: $black;
          font-size: 28px;
          line-height: 34px;
          margin-bottom: 17px;
          max-width: 290px;
          font-weight: 500;

          @include breakpoint-portrait-850px {
            line-height: 34px;
          }
      }

      .text-content {
          font-size: 16px;
          line-height: 26px;
          font-weight: 400;
      }
    }

    &.content-page {
      .text-image {
        h1 {
          @include breakpoint-portrait-850px {
            line-height: 40px;
          }
        }
        
        .text-content {
            line-height: 20px;
        }
      }
    }
  }

  .text-image {
      max-width: 840px;
      padding-left: 157px;
      padding-right: 157px;
      margin-bottom: 60px;
      text-align: left;

      @include breakpoint-portrait-850px {
        margin-bottom: 40px;
      }

      @include breakpoint-landscape-1100px {
        padding-left: 60px;
        padding-right: 60px;
      }

      h1 {
          color: $black;
          font-size: 34px;
          line-height: 46px;
          margin-bottom: 40px;
          font-weight: 500;
      }

      .text-content {
        font-size: 26px;
        line-height: 36px;
        margin-bottom: 60px;

        @include breakpoint-portrait-850px {
          margin-bottom: 20px;
          font-size: 16px;
        } 
        
        ::v-deep a{
          color:#707070;
        }
      }     
      
      img {
        margin-bottom: 28px;
        max-width: 60%;

        @include breakpoint-portrait-850px {
          margin-bottom: 0;
          max-width: 100%;
        }
      }

      .caption {
        font-size: 12px;
        line-height: 20px;

        @include breakpoint-portrait-850px {
            margin-bottom: 20px;
          }
      }
  }
</style>

<style lang="scss">
  .left-content-container {
    .text-image {         
      .text-content {         
        @include breakpoint-portrait-850px {
          line-height: 20px;

          h3 {
            font-size:28px;
            line-height:40px;
          }

          h4,
          .subtitle {
            font-size:20px;
            line-height: 26px;
          }
        }
      }
    }
  }

  .text-image {
    .text-content {        
      h3 {
        font-weight:500;
        font-size:34px;
        line-height:46px;
      } 
      
      h4,
      .subtitle {
        margin:0;
      }
    }                
  }
</style>
