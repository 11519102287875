<template>
  <div>
    <div class='places-list-wrapper' v-if="showBasel">
      <div class='places-title'>Radio-Orte in Basel</div>
      <div class='places-title-mobile'>{{titleBasel}}</div>
      <div class='places-list'>
        <places-list-item v-if="location === 'placeOverview' || location === 'placeBasel'" v-for="place in placesBasel" :place='place' :user-location='userLocation' :main-place-coordinates='mainPlacePosition'></places-list-item>
      </div>
    </div>

    <div class='places-list-wrapper' v-if="showLiestal">
      <div class='places-title'>Radio-Orte in Liestal</div>
      <div class='places-title-mobile'>{{titleLiestal}}</div>
      <div class='places-list'>
        <places-list-item v-if="location === 'placeOverview' || location === 'placeLiestal'" v-for="place in placesLiestal" :place='place' :user-location='userLocation' :main-place-coordinates='mainPlacePosition'></places-list-item>
      </div>
    </div>
  </div>
</template>

<script>
import { haversine_distance, sort_tiles } from '../utils';
import PlacesListItem from './PlacesListItem.vue';
export default {
  name: "PlacesList",
  props: ["places", "userLocation", "location"],
  components: {
    PlacesListItem
  },
  data() {   
    return {
      mainPlacePosition: null,
      placesBasel: this.places.filter(place => place.section === 'placeBasel'),
      placesLiestal: this.places.filter(place => place.section === 'placeLiestal'),
      showBasel: false,
      showLiestal: false,
      titleBasel: this.location === 'placeOverview' ? 'Radio-Orte in Basel' : 'In der Nähe',
      titleLiestal: this.location === 'placeOverview' ? 'Radio-Orte in Liestal' : 'In der Nähe'
    }   
  },
  mounted() {
    this.setPlacesByDistance();
  },
  watch: {
    userLocation: function() {
      this.setPlacesByDistance();
    }
  },
  methods: {
    setPlacesByDistance(){
      let placesNumber = 10;

      if(this.location === 'placeOverview') {
        if(this.userLocation) {
          // Calculate distance to Basel and Liestal from user's location
          const distanceToBasel = haversine_distance(this.userLocation.position, this.$centerOptions.placeBasel);
          const distanceToLiestal = haversine_distance(this.userLocation.position, this.$centerOptions.placeLiestal);
          this.mainPlacePosition = Object.values(this.$centerOptions.placeOverview).join(', ');

          // Determine which places to show based on the user's location
          if (distanceToBasel > 20 && distanceToLiestal > 20) {
            // User is more than 20km from placeOverview, show 5 places from each Basel and Liestal
            placesNumber = 5;
            this.showLiestal = true;
            this.showBasel = true;
          } else if (distanceToBasel < distanceToLiestal) {
            // User is closer to Basel
            placesNumber = 10;
            this.showLiestal = false;
            this.showBasel = false;
            this.mainPlacePosition = Object.values(this.$centerOptions.placeBasel).join(', ');

            // Forces re-render
            this.$nextTick(() => {
              this.showBasel = true;
              this.$nextTick(() => {
                sort_tiles(placesNumber);
              });
            });
          } else {
            // User is closer to Liestal or distances are equal
            placesNumber = 10;
            this.showLiestal = false;
            this.showBasel = false;
            this.mainPlacePosition = Object.values(this.$centerOptions.placeLiestal).join(', ');

            // Forces re-render
            this.$nextTick(() => {
              this.showLiestal = true;
              this.$nextTick(() => {
                sort_tiles(placesNumber);
              });
            });
          }
        } else {
          // User location is not available, show 5 places from each Basel and Liestal
          placesNumber = 5;
          this.showLiestal = true;
          this.showBasel = true;
        }
      } else if (this.location === 'placeBasel') {
        this.showBasel = true;
        this.mainPlacePosition = Object.values(this.$centerOptions.placeBasel).join(', ');
      } else if (this.location === 'placeLiestal') {
        this.showLiestal = true;
        this.mainPlacePosition = Object.values(this.$centerOptions.placeLiestal).join(', ');
      }

      this.$nextTick(() => {
        sort_tiles(placesNumber);
      }); 
    }
  }
};
</script>

<style lang="scss">
  .places-title{
    font-size:14px;
    text-transform: uppercase;
    font-weight:500;
    margin-left:16px;
    margin-bottom:10px;

    @include breakpoint-portrait-850px {
      display:none;
    }
  }

  .places-title-mobile{
    display:none;
    font-size:14px;
    text-transform: uppercase;
    font-weight:500;
    margin-left:16px;
    margin-bottom:10px;

    @include breakpoint-portrait-850px {
      display:block;
    }
  }

  .places-list{
    display:flex;
    flex-direction:column;

    @include breakpoint-portrait-850px {
      padding-left: 0;
      padding-right: 0;
      margin-bottom:45px;
    }
  }

  .places-list-wrapper:not(:last-child) {
    margin-bottom: 40px;
  }
</style>
