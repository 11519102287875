<template>
  <a :href="place.uri" class="place">
    <img :src="place.poster">
    <div class="info">
      <span class="date">{{ place.show }} {{ place.date }} | {{ place.duration }}</span>
      <span class="title">{{ place.title }} </span>
      <span class="distance">{{ distance }} Km </span>
    </div>
  </a>
</template>

<script>
import Vue from "vue";
import { haversine_distance } from '../utils';
export default {
  name: "PlacesListItem",
  props: ["place", "userLocation", "isRelatedPlace", "mainPlaceCoordinates"],
  data() {   
    return {
      position:{
        lat:this.place.coordinates.split(',')[0].trim(),
        lng:this.place.coordinates.split(',')[1].trim()
      },
      mainPlacePosition: this.mainPlaceCoordinates ? {
        lat:this.mainPlaceCoordinates.split(',')[0].trim(),
        lng:this.mainPlaceCoordinates.split(',')[1].trim()
      } : null

    }   
  },
  methods:{
    
  },
  computed:{
    distance(){

      let distance = null;

      if(this.isRelatedPlace && this.mainPlacePosition){
        distance = haversine_distance(this.position, this.mainPlacePosition).toFixed(2);
      } else if(this.userLocation){
        distance = haversine_distance(this.position, this.userLocation.position).toFixed(2);
      }

      if((this.userLocation === null || distance > 20) && !this.isRelatedPlace){
        distance =  haversine_distance(this.position, this.mainPlacePosition ?? this.$centerOptions.placeOverview).toFixed(2);
      }

      return distance;

    }
  }
};
</script>

<style lang="scss">
  .place{
    margin: 5px 0;
    width:100%;
    display:flex;
    flex-direction:row;
    min-height:105px;
    box-sizing: border-box;
    text-decoration:none;
    color:black;

    @include breakpoint-portrait-850px {
      width: 100%;
    }

    img{
      display:block;
      height:auto;
      width:126px;
      object-fit: cover;
      border-top-left-radius: 11px;
      border-bottom-left-radius: 11px;
    }

    .info {
      background-color: white;
      flex:1;
      border-top-right-radius: 11px;
      border-bottom-right-radius: 11px;
      padding:15px;
      word-break: break-word;
      padding-bottom:40px;
      position:relative;

      span{
        display:block;

        &.date{
          font-size:10px;
          text-transform: uppercase;
        }

        &.title{
          font-size:18px;
          font-weight:700;
          line-height:23px;
        }
        
        &.distance{
          font-size:14px;
          position:absolute;
          bottom:10px;
        }
      }
    }
  }
</style>

<style lang="scss">
  .places-title{
    font-size:14px;
    text-transform: uppercase;
    font-weight:500;
    margin-left:16px;
    margin-bottom:10px;

    @include breakpoint-portrait-850px {
      display:none;
    }
  }

  .places-title-mobile{
    display:none;
    font-size:14px;
    text-transform: uppercase;
    font-weight:500;
    margin-left:16px;
    margin-bottom:10px;

    @include breakpoint-portrait-850px {
      display:block;
    }
  }

  .places-list{
    display:flex;
    flex-direction:column;

    @include breakpoint-portrait-850px {
      padding-left: 0;
      padding-right: 0;
      margin-bottom:45px;
    }
  }
</style>
