<template>
    <div>
        <div class="footer" ref="container">
            <ul>
                <li v-for="value in componentData.links" class="nav-link">
                    <a :href="value.uri">{{ value.title }}</a>                           
                </li>
            </ul>
            <img class="logo" src="/assets/SRG_Logo_Weisser_Text.svg">
        </div>
        <div class="spacer" ref="spacer"></div>
    </div>
</template>

<script>
export default {
  name: "Footer",
  props: ["componentData"],
  mounted() {
  }
};
</script>

<style lang="scss">
    .footer {
        background-color: $dark-grey;
        padding-left: 58px;
        padding-top: 40px;

        @include breakpoint-portrait-850px {
            padding-left: 50px;
        }

        ul {
            margin-bottom: 65px;
            padding-left: 0;
            list-style: none;
            
            li {
                a {
                    text-decoration: none;
                    color: $white;
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }

        .logo {
            width: calc(125px);
            margin-bottom: 50px;
            height: auto;

            @include breakpoint-portrait-850px {
                margin-bottom: 85px;
            }
        }
    }
</style>