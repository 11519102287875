<template>
  <div v-html="code">
  </div>
</template>

<script>
export default {
  name: "HTMLComponent",
  props: ["componentData"],
  data() {
    return {
      code: this.componentData.code   
    }   
  }
};
</script>
