<template>
  <div class="map-wrapper">
    <gmap-map
      :zoom="processedZoom"    
      :center="center"
      :options="{styles: styles, disableDefaultUI: true, gestureHandling: 'cooperative'}"
      style="width:100%;  height: 100%;">

      <gmap-custom-marker 
        alignment="center"
        :key="index"
        v-for="(m, index) in locationMarkers"
        :marker="m[0].position"
        :clickable="true"
        :class="{highlightedmarker: m[0].highlight}"
        @mouseenter.native="mouseOverMarker(m[0],m[0].id,$event)"
        @mouseleave.native="mouseLeaveMarker(m[0],m[0].id,$event)"
        @touchend.native="touchOverMarker(m[0],m[0].id,$event)"
        tabindex="0">

        <div class="marker-wrapper" :class="{offset: isOverlappedMarker(m), overview: location === 'placeOverview'}">
          <div class="marker" v-for="(item) in m"
            :class="{active: isActive(item.id) || item.highlight, 
                    disabled: item.disabled, highlighted: item.highlight,
                    overview: location === 'placeOverview'}">
            <a v-if="!item.highlight" :href="item.uri"></a>
            <span v-if="item.markerText">{{ item.markerText }}</span>
          </div>
        </div>
        
      </gmap-custom-marker>

      <gmap-info-window
        :opened="infoWinOpen"
        :position="infoWindowPos"           
        :options="infoOptions"
        :zIndex="999"
        @closeclick="infoWinOpen=false">
      </gmap-info-window>

    </gmap-map>
  </div>
</template>

<script>
import Vue from "vue";
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import { haversine_distance } from '../utils';

export default {
  name: "GMap",
  props: ["places", "userLocation", "zoom", "highlights", "location", "isMobile", "mainPlaceCoordinates"],
  components: {
      'gmap-custom-marker': GmapCustomMarker
  },
  data() {
    return {
      center: this.location ? this.$centerOptions[this.location] : this.$centerOptions.placeOverview,
      globalCenter: this.$centerOptions.placeOverview,
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      isMouseOverInfoWindow: false,
      isMouseOverMarker: false,
      setHighlights: this.highlights ?? true,
      processedZoom: this.zoom,
      mainPlacePosition: this.mainPlaceCoordinates ? {
        lat:this.mainPlaceCoordinates.split(',')[0].trim(),
        lng:this.mainPlaceCoordinates.split(',')[1].trim()
      } : null,

      infoOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -10
        }
      },
      styles: [
        {
          featureType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "road",
          stylers: [
            { visibility: "on"}, 
            { saturation: -100 },
            { lightness: -25 }
          ],
        },
        {
          featureType: "road",
          elementType: "labels",
          stylers: [{visibility: "on" }]
        },
        {
          featureType: "road.highway",
          elementType: "labels",
          stylers: [{visibility: "on" }]
        },
        {
          featureType: "road.arterial",
          elementType: "labels",
          stylers: [{visibility: "on" }]
        },
        {
          featureType: "administrative.locality",
          stylers: [{ visibility: "on" }],
        }, 
        {
          featureType: "transit",
          stylers: [{ visibility: "on" }],
        },
        {
          featureType: "transit",
          elementType: "labels",
          stylers: [{visibility: "off" }]
        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [
            {
                saturation: 100
            },
            {
                lightness: "722"
            },
            {
                visibility: "on"
            },
          ]
        },
        {
          featureType: "landscape.man_made.building",
          elementType: "geometry.fill",
          stylers: [
            {
                color: "#cccccc"
            },
            {
                lightness: "50"
            },
            {
                visibility: "on"
            },
          ]
        },
        {
          featureType: "landscape.man_made.building",
          elementType: "geometry.stroke",
          stylers: [
            {
                color: "#cccccc"
            }
          ]
        },
      ]      
    }   
  },
  computed:{
    locationMarkers(){
      let markers = [];

      if(this.location === 'placeOverview'){
        this.mainLocations = [this.$centerOptions.placeBasel, this.$centerOptions.placeLiestal];
        this.locationsLabels = ['Basel', 'Liestal'];

        for(const place of this.mainLocations){
          const position =  place;

          const newPlace = {
            position: position,
            uri: '/' + this.locationsLabels[this.mainLocations.indexOf(position)].toLowerCase(),
            infoText: null,
            markerText: this.locationsLabels[this.mainLocations.indexOf(position)]
          };

          markers.push([newPlace]);
        }

      }

      else {
        for(const place of this.places){
          if(place.highlight && this.setHighlights){
            this.center = place.position;
            if(this.isMobile){
              this.processedZoom = 16;
            } else {
              this.processedZoom = 18;
            }
          }
        }

        for(const place of this.places){

          const position =  {
            lat: parseFloat(place.coordinates.split(',')[0].trim()), 
            lng: parseFloat(place.coordinates.split(',')[1])
          };

          let distance = '0.00';

          if(this.userLocation){
            distance =  haversine_distance(position, this.userLocation.position).toFixed(2);
          } 

          if(this.userLocation == null || distance > 20) {
            if(this.location === 'placeBasel'){
              distance =  haversine_distance(position, this.mainPlacePosition ?? this.$centerOptions.placeBasel).toFixed(2);
            } else if(this.location === 'placeLiestal'){
              distance =  haversine_distance(position, this.mainPlacePosition ?? this.$centerOptions.placeLiestal).toFixed(2);
            } else {
              distance =  haversine_distance(position, this.$centerOptions.placeOverview).toFixed(2);
            }
          }

          const newPlace = {
            id: place.id,
            disabled: (place.disabled  ?? false) && this.setHighlights,
            highlight: (place.highlight ?? false) && this.setHighlights,
            position: position,
            distance: distance,
            uri: place.uri,
            infoText: this.location === 'placeOverview' ? '' : `<div class="infoContent place" >
              <a href="${place.uri}"><img src="${place.poster}"></a>
              <div class="info">
                <span class="date">${ place.show } ${ place.date } | ${ place.duration }</span>
                <a href="${place.uri}"><span class="title">${ place.title } </span></a>
                <span class="distance">${ distance } Km </span>
              </div>
            </div>`
          };

          if(newPlace.highlight){
            this.center = newPlace.position;
          }

          // Add marker to the same marker wrapper if there's another at the same coordinates
          const index = markers.findIndex(m=>JSON.stringify(m[0].position) === JSON.stringify(newPlace.position)) ?? null;
        
          if(index > -1){
            markers[index].push(newPlace);
          } else {
            markers.push([newPlace]);
          }
          
        };
      }

      return markers;
    }
  },
  mounted(){
    if(this.location === 'placeOverview'){
      this.processedZoom = 11;
    } else if (this.location === 'placeBasel'){
      if(this.isMobile){
        this.processedZoom = 13;
      } else {
        this.processedZoom = 14;
      }
    } 
  },
  methods:{
    triggerSiblings(eventTarget, leave) {
      const top = eventTarget.style.top;
      const left = eventTarget.style.left;
      const markers = document.querySelectorAll('.marker-wrapper');
      
      for (let i = 0; i < markers.length; i++) {
        const otherTop = markers[i].parentNode.style.top;
        const otherLeft = markers[i].parentNode.style.left;

        if (otherTop === top && otherLeft === left) {
          if (!leave && !markers[i].querySelector('.marker').classList.contains('disabled')) {
            markers[i].querySelector('.marker').classList.add('active');
          } else {
            if (!markers[i].querySelector('.marker').classList.contains('highlighted')) {
              markers[i].querySelector('.marker').classList.remove('active');
            }
          }
        }
      }
    },
    mouseOverMarker(marker, idx, event){
      let otherPlaces = this.places.filter(place => {
        return place.coordinates.replaceAll(' ', '') === `${marker.position.lat},${marker.position.lng}` && place.id !== marker.id && !place.highlight;
      });

      if(!marker.highlight || otherPlaces.length > 0){
        this.infoWindowPos = marker.position;
        const mainContent = !marker.highlight ? marker.infoText : '';
        this.currentMidx = !marker.highlight ? idx : null;
        this.infoOptions.content = '<div class="infoContentWrapper" tabindex="0">' + mainContent + '</div>';
        if(marker.infoText !== null){
          this.infoWinOpen = true;
          this.isMouseOverMarker = true;
        }
      }

      this.triggerSiblings(event.currentTarget, false);

      for(const place of otherPlaces){
        const newContent = `<div class="infoContent place">
          <a href="${place.uri}"><img src="${place.poster}"></a>
            <div class="info">
              <span class="date">${ place.show } ${ place.date } | ${ place.duration }</span>
              <a href="${place.uri}"><span class="title">${ place.title } </span></a>
              <span class="distance">${ marker.distance } Km </span>
            </div>
          </div>`

        this.infoOptions.content = this.infoOptions.content.replace('<div class="infoContentWrapper" tabindex="0">', '<div class="infoContentWrapper" tabindex="0">' + newContent);
        if(marker.highlight){
          this.currentMidx = place.id;
        }
      }
    
      this.$nextTick(() => {
        const element = document.querySelector('.infoContentWrapper');
        const originalEventTarget = event.currentTarget;
        element?.addEventListener('blur', (ev)=>{
          this.touchLeaveMarker(marker, idx, ev);
          this.triggerSiblings(originalEventTarget, true);
        });
        element?.addEventListener('mouseenter', this.mouseOverInfoWindow);
        element?.focus(); 
      });        
            
    },
    mouseLeaveMarker(marker, idx, event){
      this.isMouseOverMarker = false;
      this.triggerSiblings(event.currentTarget, true);
      setTimeout(function(){
        if(!this.isMouseOverInfoWindow && this.currentMidx === idx){
          this.infoWinOpen = false;
          this.currentMidx = null;          
        }
      }.bind(this), 100);     
    },  
    mouseOverInfoWindow(){
      this.isMouseOverInfoWindow = true;
      const element = document.querySelector('.infoContentWrapper');
      element?.addEventListener('mouseleave', this.mouseLeaveInfoWindow);
    },
    mouseLeaveInfoWindow(){
      this.isMouseOverInfoWindow = false;
      setTimeout(function(){
        if(!this.isMouseOverMarker){
          this.infoWinOpen = false;
          this.isMouseOverInfoWindow = false;
          this.currentMidx = null;           
        }     
      }.bind(this), 100);        
    },
    touchOverMarker(marker, idx, event){
      if(this.location !== 'placeOverview') {
        if (event.cancelable) {
          event.preventDefault();
        }
        this.mouseOverMarker(marker, idx, event);
      } else {
        if(marker.uri) {
          window.location.href = marker.uri; // Navigate to the URL.
        }
      }
    },
    touchLeaveMarker(marker, idx, event){
      event.preventDefault();
      this.isMouseOverMarker = false;

      if(idx === this.currentMidx){
        this.mouseLeaveInfoWindow(marker, idx);
      }
    },
    isActive(idx){
      return idx && idx === this.currentMidx;
    },
    isOverlappedMarker(wrapper) {
      return wrapper.length  > 1;
    }
  }
};
</script>

<style lang="scss">
  .map-wrapper{
    width:100%;
    height:100%;
  }

  .map-title{
    height:50px;
    background:white;
    margin:0 -10px;
    
    font-size:14px;
    padding-left:30px;
    display:flex;
    align-items: center;
    text-transform: uppercase;
    font-weight:500;
  }

  .map-title-desktop{
    position: absolute;
    top: 0;
    margin: 30px 50px;
    font-size: 26px;
    z-index: 1;
    text-transform: capitalize;
  }

  .vue-map{
    height:100%;

    @include breakpoint-portrait-850px {
      position: relative !important;
      height: 370px;
      width: 100vw;
      margin: 0 0 50px -10px;
    }
  }

  .gm-style-iw{
    &.gm-style-iw-c{
      padding:0;
      box-shadow: none;
      background-color:transparent;

      @include breakpoint-1260px {
        overflow:visible;
      }

      .gm-style-iw-d{
        overflow:hidden !important;
        margin: -15px;
        padding: 15px;

        @include breakpoint-1260px {
          overflow:visible !important;
        }
      }

      button{
        display:none !important;
      }
    }  
  }

  .gm-style-iw-tc{
    display:none !important;
  }

  .infoContentWrapper{
    display:flex;

    @include breakpoint-1260px {
      flex-direction:column;
      margin-top:-60px;  
    }  

    &:focus-visible{
      outline: none;
    }

    .infoContent.place{
      margin:10px;
      width:auto;
      max-width:360px;
      box-shadow: 0 2px 7px 1px rgba(0,0,0,.3);
      position:relative;
      border-radius:10px;

      &:focus-visible{
        outline: none;
      }

      @include breakpoint-1260px {
        margin-top:0;  
      } 

      a {
        color:black;
        text-decoration: none;

        &:focus-visible {
          outline: none;
        }

        span {
          @include breakpoint-portrait-850px {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      img {
        height:100%;

        @include breakpoint-1260px {
          width:65px;
        }
      }
    }
  } 
</style>

<style scoped lang="scss">
  .highlightedmarker {
    pointer-events: none;
  }
  .marker-wrapper{
    display:flex;
    justify-content: center;
    align-items: center;
    height:45px;
    width:45px;
    pointer-events: none;

    &.overview{
      height:85px;
      width:85px;
    }

    &.offset {
      width: 90px;

      .marker{
        margin: 0 -2px;
      }
    }

    .marker{
      height:25px;
      width:25px;
      border-radius:50%;
      background-color: $red;
      transition:all 0.2s linear;

      a{
        display:block;
        width:100%;
        height:100%;
        position:relative;
        z-index:999;
        pointer-events: all;

        @include breakpoint-portrait-850px {
          pointer-events: none;
        }
      }

      span {     
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        height: 85px;
        width: 85px;
        justify-content: center;
        align-items: center;
        color: white;
        font-size:16px;
      }

      &.overview{
        height:75px;
        width:75px;
      }

      &.active,
      &.active:not(.highlighted) + .marker{
        height:35px;
        width:35px;

        &.overview{
          height:85px;
          width:85px;
        }
      }

      &.disabled{
        background-color:$dark-grey;     
      }

      &.offset {
        margin-left: -45px;
        pointer-events: all;
      }
    }
  } 
</style>
