<template>
  <header class="header">
    <a href="/">
      <div class="logo">
        <img class="logo-speaker" src="/assets/ro-logo.svg" />
        <div class="logo-group">
            <img class="logo-black" src="/assets/Radio_Orte_SRG_Logo.svg" />
        </div>  
      </div>
    </a>
    <div class="menu-toggle" aria-controls="main-menu" aria-expanded="false" aria-label="Toggle navigation" role="navigation"
    :class="{ open: isOpen }" 
    @click="menuToggle">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
    <Transition name="fade">
      <nav id="main-menu" class="main-menu"
        v-show="isOpen">
          <ul>
              <li v-for="value in componentData.links" class="nav-link" @click="menuToggle">
                  <a :href="value.uri">{{ value.title }}</a>                           
              </li>
          </ul>

          <span v-html="logo" class="menu-logo"></span>

        </nav>

    </Transition>
    
                                
  </header>
</template>

<script>
import { logo_svg } from '../utils';
export default {
  name: "Navigation",
  props: ["componentData"],
  data() {
    return {
      isOpen: false,
      logo: logo_svg
    }   
  },
  methods:{
    menuToggle (){
      this.isOpen = !this.isOpen;     
    }
  }
};
</script>


<style scoped lang="scss">
.header{
  position:fixed;
  width:$side-width;
  top:0;
  left:0;
  z-index:3;
  background-color: $yellow;
  display:flex;
  justify-content: space-between;
  align-items: top;
  height:195px;
  padding-top:51px;
  padding-bottom:34.5px;
  padding-left:46px;
  padding-right:47px;
  box-sizing: border-box;

  @include breakpoint-portrait-850px {
    width: 100%;
    height: 146px;
    padding: 18px;
  }

    .logo {
      display: flex;
      flex-direction: row;
      height: 100%;

      .logo-speaker{
        @include breakpoint-portrait-850px {
          height: 90%;
        }

        @include breakpoint-377px {          
          display:none;                  
        }
      }

      .logo-group {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 30px 0 0 13px;

        @include breakpoint-portrait-850px {
          height: auto;
        }

        .logo-black {
          width: 150px;
          margin-bottom: 15px;

          @include breakpoint-portrait-850px {
            width: 135px;
          }
        }

        .logo-white {
          width: 105px;
        }
      }
    }
    
    .main-menu {
      position:fixed;
      top:0;
      left:0;
      bottom:0;
      display:flex;
      flex-direction:column;
      justify-content: space-between;
      background-color: $dark-grey;
      width:$side-width;
      padding:60px 70px 100px 70px;
      box-sizing: border-box;

      @include breakpoint-portrait-850px {
        width: 100%;
      }

      .menu-logo{
        width:245px;
        height:245px;
        align-self:center;
      }
    }

    .main-menu > ul {
      display: flex;
      flex-direction:column;
      list-style-type: none;
      margin: 100px 0;
      padding:0;

      li {
        margin-bottom: 15px;
        text-align:left;
        display:block;
        font-size: 24px;
        font-weight:500;

        a {
          text-decoration: none;
          color: white;
          cursor:pointer;

          &:hover{           
            color:grey;            
          }
        }        
      }
    }
  
  .menu-toggle{
    position:relative;
    width: 40px;
    height: 26px;   
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index:999;
    margin-top: 27px;

    span {
      display: block;
      position: absolute;
      height: 4px;
      width: 100%;
      background: black;
      border-radius: 1px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }

    span:nth-child(1) {
      top: 0px;
    }

    span:nth-child(2), span:nth-child(3) {
      top: 11px;
    }

    span:nth-child(4) {
      top: 22px;
    }

    &.open{
      span{
        background-color:white;
      }
      span:nth-child(1),
      span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        width:70%;
        top:13px;
      }

      span:nth-child(3),
      span:nth-child(4) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        width:70%;
        top:-4px;
      }
    }
  }  
}
</style>
