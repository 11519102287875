<template>
    <div class="share-button">
        <span @click="share()">TEILEN</span>
        <img src="/assets/icons8-share.svg"  @click="share()"/>
        <div class="share-menu" v-if="this.shareMenuOpen">
            <a :href="twitterShareURL" target="_blank"><img src="/assets/twitter.svg" /></a>
            <a :href="facebookShareURL" target="_blank"><img src="/assets/facebook.svg" /></a>
            <a :href="linkedInShareURL" target="_blank"><img src="/assets/linkedin.svg" /></a>
            <a :href="mailShareURL" target="_blank"><img src="/assets/mail.svg" /></a>
        </div>
    </div>            
</template>

<script>
    export default {
        name: 'ShareButton',
        props: ['componentData'],
        data() {
            return {
                isMobile: 'share' in window.navigator,
                shareMenuOpen: false,
                facebookShareURL: 'https://www.facebook.com/share.php?u=' + encodeURIComponent(window.location.href),
                twitterShareURL: 'http://twitter.com/share?url=' + encodeURIComponent(window.location.href),
                linkedInShareURL: 'https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(window.location.href),
                mailShareURL: 'mailto:?body=' + encodeURIComponent(window.location.href)
            }
        },
        methods: {
            share() {
                if (this.isMobile) {
                    navigator.share({
                        title: this.componentData.title,
                        url: window.location.href,
                        text: this.componentData.text
                    })
                } else {
                    this.shareMenuOpen = !this.shareMenuOpen;
                }
            }
        }
    };
</script>

<style lang="scss">
    .share-button {
        display: flex;
        align-items: center;
        user-select: none;

        span {
            font-size: 14px;
            line-height: 23px;
            letter-spacing: 0.7px;
            color: $black;
            text-transform: uppercase;
            margin-right: 8px;
            cursor: pointer;
            font-weight:500;
        }

        img {
            cursor: pointer;
        }

        .share-menu {
            position: absolute;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background-color: $white;
            width: 330px;
            height: 50px;
            padding: 20px 45px;
            margin: 0 30px;
            left: 0;
            top: 100px;
            box-shadow: 1px 5px 12px rgba(0, 0, 0, 0.29);
            border-radius: 12px;

            img {
                width: 50px;
            }

            &::before {
                content: '';
                position: absolute;
                top: -24px;
                left: 356px;
                width: 0px;
                height: 0px;
                border-style: solid;
                border-width: 0 18px 28px 18px;
                border-color: transparent transparent #FFFFFF transparent;
                transform: rotate(0deg);
            }
        }
    }
</style>