<template>
    <div class="mobile-content">
        <slot v-if="isMobileScreen"></slot>
    </div>
</template>

<script>
    export default {
        name: "MobileContentContainer",
        data() {
            return {
                isMobileScreen: false
            }
        },
        methods: {
            isMobile() {
                return window.screen.availWidth <= 850;
            }
        },
        mounted() {
            this.isMobileScreen = this.isMobile()
            window.addEventListener("resize", () => {
                this.isMobileScreen = this.isMobile();
            });
        }
    }
</script>