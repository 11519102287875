import Vue from "vue";
import { sort_tiles } from "./utils";
import * as VueGoogleMaps from "vue2-google-maps";
import * as VueCookie from "vue-cookie";
import Components from "./components";
import './styles/global.scss';

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GMAPS_API_KEY
  }
});

Vue.prototype.$centerOptions = {       
  placeBasel: {
    lat:47.548763,
    lng: 7.591613
  },
  placeLiestal: {
    lat: 47.484448,
    lng: 7.733532
  },
  placeOverview: {
    lat: 47.526294,
    lng: 7.671417
  }
};

Vue.use(VueCookie);

new Vue({
  el: "#app",
  delimiters: ["${", "}"],
  methods: {
    scrollToSearchBar() {
      if (document.querySelector('suchen') > -1) {
        const searchBar = document.querySelector('#suchen');
        const searchBarRect = searchBar.getBoundingClientRect();
        const parentRect = searchBar.parentElement.getBoundingClientRect();
        document.querySelector('#left').scrollTop = searchBarRect.y - parentRect.y;
        searchBar.querySelector('input').focus();
        window.location.hash = 'suche';
      }
    },
    initializeCookieBanner() {
      if(this.$cookie.get('cookie_notice_closed') == null || !this.$cookie.get('cookie_notice_closed')){
        document.querySelector('.cookie-banner')?.classList.add('show');
        document.querySelector('#left')?.classList.add('cookie-offset');
      }

      document.querySelector('.cookie-banner-ok')?.addEventListener('click', ()=>{
        document.querySelector('.cookie-banner')?.classList.remove('show');
          this.$cookie.set('cookie_notice_closed', true, { expires: '1Y', domain: document.location.hostname });
      });
    }
  },
  data() {
    return {
      userLocation: null
    }
  },
  mounted(){
    navigator.geolocation.getCurrentPosition(
      position => {
        this.userLocation = {
          position: {
            lat:position.coords.latitude,
            lng:position.coords.longitude
          }          
        };

        this.$nextTick(() => {
          sort_tiles();
        });  
      },
      error => {
        this.userLocation = null;
      },
    );

    if (window.location.hash.indexOf('suche') > -1) {
      this.scrollToSearchBar();
    }
    window.addEventListener('hashchange',() => {
      if (window.location.hash.indexOf('suchen')) {
        this.scrollToSearchBar();
      }
    });

    this.$nextTick(() => {
      sort_tiles();
    });

    window.addEventListener("resize", () => {
      sort_tiles();
    });

    this.$el.style.opacity = 1;

    this.initializeCookieBanner();
  }
});
