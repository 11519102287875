export const haversine_distance = function(pos1, pos2) {
  var R = 6371.0710; // Radius of the Earth in miles
  var rlat1 = pos1.lat * (Math.PI/180); // Convert degrees to radians
  var rlat2 = pos2.lat * (Math.PI/180); // Convert degrees to radians
  var difflat = rlat2-rlat1; // Radian difference (latitudes)
  var difflon = (pos2.lng-pos1.lng) * (Math.PI/180); // Radian difference (longitudes)

  var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
  return d;
}

export const sort_tiles = function(slice = 0) {
  const placesLists = document.querySelectorAll('.places-list');
  
  placesLists.forEach((placesList) => {
    let placeTiles = Array.prototype.slice.call(placesList.querySelectorAll('.place', 0));
    let size = placeTiles.length;

    if (slice > 0 && slice < placeTiles.length) {
      size = slice;
    }

    if (placeTiles.length > 0) {
      placeTiles = placeTiles.sort((tile1, tile2) => {
        const dist1 = parseFloat(tile1.querySelector('.distance').innerHTML.replace(/[^0-9.,]/g, ''));
        const dist2 = parseFloat(tile2.querySelector('.distance').innerHTML.replace(/[^0-9.,]/g, ''));
        if (dist1 < dist2) return -1;
        if (dist1 > dist2) return 1;
        return 0;
      });

      placesList.innerHTML = '';
      for (let i = 0; i < size; i++) {
        placesList.appendChild(placeTiles[i]);
      }
    }
  });
  
  const allPlacesLists = document.querySelectorAll('.places-teaser-list');
  const allPlacesListsMobile = document.querySelectorAll('.places-teaser-list-mobile');

  allPlacesLists.forEach((placesList) => { sort(placesList); });
  allPlacesListsMobile.forEach((placesListMobile) => { sort(placesListMobile); });
}

const sort = function(container){
  if(container == null){
    return;
  }
  
  let placeBigTiles = Array.prototype.slice.call(container.querySelectorAll('.place-tile-wrapper', 0));
  if (placeBigTiles.length > 1) {

    placeBigTiles = placeBigTiles.sort((tile1, tile2) => {
      const dist1 = parseFloat(tile1.querySelector('.distance').value);
      const dist2 = parseFloat(tile2.querySelector('.distance').value);
      if (dist1 < dist2) return -1;
      if (dist1 > dist2) return 1;
      return 0;
    });

    let batches = [];
    const chunkSize = 3;
    for (let i = 0; i < placeBigTiles.length; i += chunkSize) {
        const chunk = placeBigTiles.slice(i, i + chunkSize);
        batches.push(chunk);
    }

    const rows = Array.prototype.slice.call(container.querySelectorAll('.row', 0));

    for (let row = 0; row < rows.length; row++){
      const elements = batches[row];

      const columns = Array.prototype.slice.call(rows[row].querySelectorAll('.col-xl-4', 0));

      for (let index = 0; index < elements.length; index++) {
        columns[index].appendChild(elements[index]);
      }
    }
  }
}

export const logo_svg = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 141.49 141.73" style="enable-background:new 0 0 141.49 141.73;" xml:space="preserve" fill="white">
<g>
<path d="M70.65,68.55c-1.52,0.03-2.73,1.28-2.7,2.79c0.03,1.52,1.28,2.73,2.79,2.7c1.52-0.03,2.73-1.28,2.7-2.79
 C73.42,69.73,72.17,68.52,70.65,68.55"/>
<path d="M70.64,54.57c-1.68,0.03-3.02,1.41-2.99,3.09c0.03,1.68,1.41,3.02,3.09,2.99c1.68-0.03,3.02-1.41,2.99-3.09
 C73.71,55.88,72.32,54.54,70.64,54.57"/>
<path d="M85.16,62.89c-0.87-1.44-2.73-1.91-4.17-1.04c-1.44,0.87-1.91,2.73-1.04,4.17c0.87,1.44,2.73,1.91,4.17,1.04
 C85.56,66.2,86.03,64.33,85.16,62.89"/>
<path d="M84.9,79.44c0.72-1.3,0.25-2.93-1.05-3.65c-1.3-0.72-2.93-0.25-3.65,1.05c-0.72,1.3-0.25,2.93,1.05,3.65
 C82.55,81.21,84.18,80.74,84.9,79.44"/>
<path d="M70.74,87.48c1.38-0.02,2.48-1.16,2.45-2.54c-0.02-1.38-1.16-2.48-2.54-2.45c-1.38,0.02-2.48,1.16-2.45,2.54
 C68.22,86.41,69.36,87.51,70.74,87.48"/>
<path d="M56.67,79.44c0.72,1.2,2.27,1.59,3.47,0.87c1.2-0.72,1.59-2.27,0.87-3.47c-0.72-1.2-2.27-1.59-3.47-0.87
 C56.34,76.69,55.96,78.25,56.67,79.44"/>
<path d="M56.48,63.14c-0.72,1.3-0.25,2.95,1.05,3.67c1.3,0.72,2.95,0.25,3.67-1.05c0.72-1.3,0.25-2.95-1.05-3.67
 C58.85,61.37,57.2,61.84,56.48,63.14"/>
<path d="M70.64,40.62c-1.83,0.03-3.28,1.54-3.25,3.36c0.03,1.83,1.54,3.28,3.36,3.25c1.83-0.03,3.28-1.54,3.25-3.36
 C73.97,42.04,72.47,40.58,70.64,40.62"/>
<path d="M85.01,43.9c-1.75-0.88-3.87-0.17-4.75,1.57c-0.88,1.75-0.17,3.87,1.57,4.75c1.75,0.88,3.87,0.17,4.75-1.57
 C87.46,46.9,86.76,44.77,85.01,43.9"/>
<path d="M95.93,53.81c-1.07-1.49-3.14-1.83-4.63-0.77c-1.49,1.07-1.83,3.14-0.77,4.63c1.07,1.49,3.14,1.83,4.63,0.77
 C96.65,57.38,97,55.3,95.93,53.81"/>
<path d="M100.81,67.59c-0.23-1.62-1.72-2.75-3.34-2.53c-1.62,0.23-2.75,1.72-2.53,3.34c0.23,1.62,1.72,2.75,3.34,2.53
 C99.91,70.71,101.04,69.21,100.81,67.59"/>
<path d="M98.76,81.89c0.49-1.36-0.22-2.87-1.58-3.36c-1.36-0.49-2.87,0.22-3.36,1.58c-0.49,1.36,0.22,2.87,1.58,3.36
 C96.77,83.96,98.27,83.26,98.76,81.89"/>
<path d="M90.52,93.61c1-0.92,1.07-2.48,0.15-3.49c-0.92-1-2.48-1.07-3.49-0.15c-1,0.92-1.07,2.48-0.15,3.49
 C87.96,94.46,89.52,94.53,90.52,93.61"/>
<path d="M77.87,100.23c1.3-0.34,2.08-1.68,1.73-2.98c-0.34-1.3-1.68-2.08-2.98-1.73c-1.3,0.34-2.08,1.68-1.73,2.98
 C75.24,99.8,76.57,100.58,77.87,100.23"/>
<path d="M63.61,100.22c1.3,0.3,2.58-0.52,2.88-1.81c0.3-1.3-0.52-2.58-1.81-2.88c-1.3-0.3-2.58,0.52-2.88,1.81
 C61.51,98.64,62.32,99.93,63.61,100.22"/>
<path d="M50.98,93.62c1.01,0.86,2.53,0.75,3.39-0.26c0.86-1.01,0.75-2.53-0.26-3.39c-1.01-0.86-2.53-0.75-3.39,0.26
 C49.85,91.24,49.97,92.75,50.98,93.62"/>
<path d="M42.84,81.91c0.5,1.25,1.92,1.86,3.17,1.36s1.86-1.92,1.36-3.17c-0.5-1.25-1.92-1.86-3.17-1.36
 C42.95,79.24,42.34,80.66,42.84,81.91"/>
<path d="M41.07,67.74c-0.14,1.36,0.84,2.57,2.2,2.71c1.36,0.14,2.57-0.84,2.71-2.2c0.14-1.36-0.84-2.57-2.2-2.71
 C42.42,65.4,41.21,66.39,41.07,67.74"/>
<path d="M46.01,54.31c-0.79,1.19-0.47,2.8,0.72,3.59c1.19,0.79,2.8,0.47,3.59-0.72c0.79-1.19,0.47-2.8-0.72-3.59
 C48.41,52.79,46.8,53.12,46.01,54.31"/>
<path d="M56.57,44.5c-1.41,0.77-1.93,2.55-1.16,3.96c0.77,1.41,2.55,1.93,3.96,1.16c1.41-0.77,1.93-2.55,1.16-3.96
 C59.76,44.24,57.99,43.72,56.57,44.5"/>
<path d="M70.64,26.88c-1.85,0.03-3.33,1.56-3.29,3.41c0.03,1.85,1.56,3.33,3.41,3.29c1.85-0.03,3.33-1.56,3.29-3.41
 C74.02,28.32,72.49,26.85,70.64,26.88"/>
<path d="M85.17,28.96c-1.93-0.63-4.01,0.43-4.63,2.37c-0.63,1.93,0.43,4.01,2.37,4.63c1.93,0.63,4.01-0.43,4.63-2.37
 C88.16,31.66,87.1,29.58,85.17,28.96"/>
<path d="M98.11,35.97c-1.61-1.21-3.9-0.88-5.11,0.73c-1.21,1.61-0.88,3.9,0.73,5.11c1.61,1.21,3.9,0.88,5.11-0.73
 C100.05,39.47,99.72,37.18,98.11,35.97"/>
<path d="M107.85,46.95c-1.04-1.53-3.12-1.93-4.66-0.89c-1.53,1.04-1.93,3.12-0.89,4.66c1.04,1.53,3.12,1.93,4.66,0.89
 C108.49,50.57,108.89,48.49,107.85,46.95"/>
<path d="M113.37,60.44c-0.43-1.58-2.06-2.51-3.64-2.08c-1.58,0.43-2.51,2.06-2.08,3.64c0.43,1.58,2.06,2.51,3.64,2.08
 C112.86,63.65,113.8,62.02,113.37,60.44"/>
<path d="M114.27,74.86c0.1-1.46-1.01-2.73-2.48-2.82c-1.46-0.1-2.73,1.01-2.82,2.48c-0.1,1.46,1.01,2.73,2.48,2.82
 C112.91,77.43,114.18,76.32,114.27,74.86"/>
<path d="M110.61,88.76c0.53-1.27-0.07-2.74-1.34-3.27c-1.27-0.53-2.74,0.07-3.27,1.34c-0.53,1.27,0.07,2.74,1.34,3.27
 S110.08,90.03,110.61,88.76"/>
<path d="M102.71,100.71c0.88-0.99,0.79-2.51-0.2-3.4c-0.99-0.88-2.51-0.79-3.4,0.2c-0.88,0.99-0.79,2.51,0.2,3.4
 C100.31,101.79,101.83,101.7,102.71,100.71"/>
<path d="M91.41,109.48c1.14-0.64,1.55-2.09,0.9-3.23s-2.09-1.55-3.23-0.9c-1.14,0.64-1.55,2.09-0.9,3.23
 C88.82,109.72,90.27,110.13,91.41,109.48"/>
<path d="M77.89,114.14c1.29-0.24,2.14-1.48,1.9-2.77c-0.24-1.29-1.48-2.14-2.77-1.9c-1.29,0.24-2.14,1.48-1.9,2.77
 C75.36,113.52,76.6,114.38,77.89,114.14"/>
<path d="M63.59,114.12c1.28,0.19,2.47-0.69,2.66-1.97c0.19-1.28-0.69-2.47-1.97-2.66c-1.28-0.19-2.47,0.69-2.66,1.97
 C61.43,112.74,62.31,113.93,63.59,114.12"/>
<path d="M50.07,109.49c1.15,0.6,2.56,0.15,3.16-1c0.6-1.15,0.15-2.56-1-3.16c-1.15-0.6-2.56-0.15-3.16,1
 C48.48,107.48,48.92,108.9,50.07,109.49"/>
<path d="M38.79,100.73c0.89,0.94,2.38,0.97,3.31,0.08c0.94-0.89,0.97-2.38,0.08-3.31c-0.89-0.94-2.38-0.97-3.31-0.08
 C37.93,98.31,37.9,99.79,38.79,100.73"/>
<path d="M30.93,88.79c0.55,1.19,1.96,1.71,3.15,1.17c1.19-0.55,1.71-1.96,1.17-3.15s-1.96-1.71-3.15-1.17
 C30.91,86.18,30.39,87.59,30.93,88.79"/>
<path d="M27.41,74.93c0.13,1.3,1.3,2.26,2.6,2.13c1.3-0.13,2.26-1.3,2.13-2.6c-0.13-1.3-1.3-2.26-2.6-2.13
 C28.23,72.46,27.28,73.62,27.41,74.93"/>
<path d="M28.55,60.67c-0.3,1.29,0.5,2.59,1.79,2.89c1.29,0.3,2.59-0.5,2.89-1.79c0.3-1.29-0.5-2.59-1.79-2.89
 C30.15,58.57,28.85,59.37,28.55,60.67"/>
<path d="M34.2,47.51c-0.73,1.17-0.38,2.71,0.79,3.45c1.17,0.73,2.71,0.38,3.45-0.79c0.73-1.17,0.38-2.71-0.79-3.45
 C36.48,45.99,34.94,46.34,34.2,47.51"/>
<path d="M43.81,36.83c-1.14,0.92-1.32,2.59-0.4,3.73c0.92,1.14,2.59,1.32,3.73,0.4c1.14-0.92,1.32-2.59,0.4-3.73
 C46.62,36.08,44.95,35.91,43.81,36.83"/>
<path d="M56.35,29.66c-1.55,0.56-2.35,2.27-1.78,3.82c0.56,1.55,2.27,2.35,3.82,1.78c1.55-0.56,2.35-2.27,1.78-3.82
 C59.6,29.89,57.89,29.09,56.35,29.66"/>
<path d="M70.64,13.39c-1.74,0.03-3.13,1.47-3.1,3.21c0.03,1.74,1.47,3.13,3.21,3.1c1.74-0.03,3.13-1.47,3.1-3.21
 C73.82,14.75,72.38,13.36,70.64,13.39"/>
<path d="M85.11,14.91c-1.85-0.44-3.71,0.7-4.15,2.55c-0.44,1.85,0.7,3.71,2.55,4.15c1.85,0.44,3.71-0.7,4.15-2.55
 S86.96,15.35,85.11,14.91"/>
<path d="M98.71,20.21c-1.71-0.9-3.84-0.25-4.74,1.47c-0.9,1.71-0.25,3.84,1.47,4.74c1.71,0.9,3.84,0.25,4.74-1.47
 C101.08,23.24,100.42,21.12,98.71,20.21"/>
<path d="M110.45,28.88c-1.38-1.26-3.52-1.15-4.78,0.23c-1.26,1.38-1.15,3.52,0.23,4.78c1.38,1.26,3.52,1.15,4.78-0.23
 C111.94,32.27,111.84,30.13,110.45,28.88"/>
<path d="M119.52,40.25c-0.94-1.43-2.87-1.83-4.3-0.88c-1.43,0.94-1.83,2.87-0.88,4.3c0.94,1.43,2.87,1.83,4.3,0.88
 C120.07,43.61,120.46,41.68,119.52,40.25"/>
<path d="M125.44,53.46c-0.51-1.48-2.12-2.26-3.59-1.75c-1.48,0.51-2.26,2.12-1.75,3.59c0.51,1.48,2.12,2.26,3.59,1.75
 C125.17,56.54,125.95,54.93,125.44,53.46"/>
<path d="M127.93,67.65c-0.11-1.43-1.36-2.49-2.79-2.38c-1.43,0.11-2.49,1.36-2.38,2.79c0.11,1.43,1.36,2.49,2.79,2.38
 C126.98,70.33,128.04,69.08,127.93,67.65"/>
<path d="M126.91,81.98c0.23-1.34-0.67-2.62-2.01-2.85c-1.34-0.23-2.62,0.67-2.85,2.01c-0.23,1.34,0.67,2.62,2.01,2.85
 C125.4,84.22,126.68,83.32,126.91,81.98"/>
<path d="M122.41,95.58c0.54-1.2,0-2.6-1.19-3.14c-1.2-0.54-2.6,0-3.14,1.19s0,2.6,1.19,3.14
 C120.46,97.31,121.87,96.78,122.41,95.58"/>
<path d="M114.74,107.68c0.82-1.02,0.65-2.52-0.37-3.34c-1.02-0.82-2.52-0.65-3.34,0.37c-0.82,1.02-0.65,2.52,0.37,3.34
 C112.43,108.87,113.92,108.71,114.74,107.68"/>
<path d="M104.29,117.47c1.03-0.78,1.24-2.25,0.46-3.28s-2.25-1.24-3.28-0.46c-1.03,0.78-1.24,2.25-0.46,3.28
 S103.26,118.24,104.29,117.47"/>
<path d="M91.75,124.37c1.19-0.5,1.76-1.87,1.26-3.06c-0.5-1.19-1.87-1.76-3.06-1.26c-1.19,0.5-1.76,1.87-1.26,3.06
 C89.19,124.3,90.56,124.87,91.75,124.37"/>
<path d="M77.89,127.94c1.28-0.18,2.17-1.37,1.99-2.65c-0.18-1.28-1.37-2.17-2.65-1.99c-1.28,0.18-2.17,1.37-1.99,2.65
 C75.43,127.23,76.61,128.12,77.89,127.94"/>
<path d="M63.58,127.95c1.29,0.14,2.44-0.79,2.58-2.08c0.14-1.29-0.79-2.44-2.08-2.58c-1.29-0.14-2.44,0.79-2.58,2.08
 C61.37,126.65,62.3,127.81,63.58,127.95"/>
<path d="M49.72,124.4c1.21,0.46,2.56-0.16,3.02-1.37c0.46-1.21-0.16-2.56-1.37-3.02c-1.21-0.46-2.56,0.16-3.02,1.37
 C47.89,122.59,48.51,123.94,49.72,124.4"/>
<path d="M37.17,117.51c1.06,0.74,2.52,0.48,3.26-0.58c0.74-1.06,0.48-2.52-0.58-3.26c-1.06-0.74-2.52-0.48-3.26,0.58
 C35.85,115.31,36.11,116.77,37.17,117.51"/>
<path d="M26.73,107.72c0.84,0.98,2.32,1.1,3.3,0.25c0.98-0.84,1.1-2.32,0.25-3.3c-0.84-0.98-2.32-1.1-3.3-0.25
 C26,105.26,25.89,106.74,26.73,107.72"/>
<path d="M19.05,95.65c0.57,1.16,1.98,1.64,3.14,1.07c1.16-0.57,1.64-1.98,1.07-3.14c-0.57-1.16-1.98-1.64-3.14-1.07
 C18.96,93.08,18.48,94.48,19.05,95.65"/>
<path d="M14.62,82.04c0.26,1.27,1.51,2.08,2.77,1.81c1.27-0.26,2.08-1.51,1.81-2.77c-0.26-1.27-1.51-2.08-2.77-1.81
 C15.17,79.53,14.36,80.77,14.62,82.04"/>
<path d="M13.71,67.75c-0.06,1.29,0.94,2.39,2.23,2.45s2.39-0.94,2.45-2.23c0.06-1.29-0.94-2.39-2.23-2.45
 C14.87,65.46,13.77,66.46,13.71,67.75"/>
<path d="M16.35,53.68c-0.38,1.25,0.32,2.58,1.58,2.97c1.25,0.38,2.58-0.32,2.97-1.58c0.38-1.25-0.32-2.58-1.58-2.97
 C18.06,51.72,16.74,52.43,16.35,53.68"/>
<path d="M22.41,40.71c-0.69,1.13-0.33,2.61,0.8,3.31s2.61,0.33,3.31-0.8c0.69-1.13,0.33-2.61-0.8-3.31S23.11,39.57,22.41,40.71"/>
<path d="M31.5,29.61c-0.98,0.95-1,2.51-0.05,3.49c0.95,0.98,2.51,1,3.49,0.05c0.98-0.95,1-2.51,0.05-3.49
 C34.04,28.69,32.47,28.66,31.5,29.61"/>
<path d="M43.01,21.03c-1.26,0.72-1.7,2.34-0.98,3.6c0.72,1.26,2.34,1.7,3.6,0.98c1.26-0.72,1.7-2.34,0.98-3.6
 C45.89,20.74,44.27,20.3,43.01,21.03"/>
<path d="M56.32,15.51c-1.52,0.42-2.41,1.99-1.99,3.51c0.42,1.52,1.99,2.41,3.51,1.99c1.52-0.42,2.41-1.99,1.99-3.51
 C59.42,15.99,57.84,15.09,56.32,15.51"/>
<path d="M70.65,0c-1.58,0.03-2.83,1.33-2.81,2.91c0.03,1.58,1.33,2.83,2.91,2.81c1.58-0.03,2.83-1.33,2.81-2.91
 C73.53,1.23,72.22-0.03,70.65,0"/>
<path d="M85.03,1.28c-1.64-0.31-3.22,0.77-3.53,2.42c-0.31,1.64,0.77,3.22,2.42,3.53c1.64,0.31,3.22-0.77,3.53-2.42
 C87.76,3.17,86.68,1.59,85.03,1.28"/>
<path d="M98.87,5.5c-1.6-0.65-3.43,0.11-4.09,1.72c-0.65,1.6,0.11,3.43,1.72,4.09c1.6,0.65,3.43-0.11,4.09-1.72
 C101.25,7.99,100.48,6.16,98.87,5.5"/>
<path d="M111.53,12.53c-1.43-0.96-3.37-0.58-4.33,0.85s-0.58,3.37,0.85,4.33c1.43,0.96,3.37,0.58,4.33-0.85
 C113.35,15.43,112.96,13.49,111.53,12.53"/>
<path d="M122.44,22.04c-1.16-1.18-3.06-1.19-4.24-0.03c-1.18,1.16-1.19,3.06-0.03,4.24c1.16,1.18,3.06,1.19,4.24,0.03
 C123.58,25.12,123.6,23.22,122.44,22.04"/>
<path d="M131.13,33.58c-0.84-1.29-2.57-1.66-3.86-0.83c-1.29,0.84-1.66,2.57-0.83,3.86c0.84,1.29,2.57,1.66,3.86,0.83
 C131.59,36.6,131.96,34.87,131.13,33.58"/>
<path d="M137.32,46.57c-0.53-1.35-2.05-2.02-3.4-1.49c-1.35,0.53-2.02,2.05-1.49,3.4c0.53,1.35,2.05,2.02,3.4,1.49
 C137.18,49.44,137.85,47.92,137.32,46.57"/>
<path d="M140.8,60.51c-0.23-1.35-1.51-2.26-2.87-2.03c-1.35,0.23-2.26,1.51-2.03,2.87c0.23,1.35,1.51,2.26,2.87,2.03
 C140.12,63.15,141.03,61.87,140.8,60.51"/>
<path d="M141.49,74.85c0.04-1.35-1.01-2.47-2.35-2.52c-1.35-0.04-2.47,1.01-2.52,2.35c-0.04,1.34,1.01,2.47,2.35,2.52
 S141.44,76.19,141.49,74.85"/>
<path d="M139.26,89.01c0.31-1.28-0.48-2.56-1.75-2.86c-1.28-0.31-2.56,0.48-2.86,1.75c-0.31,1.28,0.48,2.56,1.75,2.86
 C137.68,91.07,138.96,90.28,139.26,89.01"/>
<path d="M134.27,102.43c0.55-1.17,0.05-2.57-1.13-3.12c-1.17-0.55-2.57-0.05-3.12,1.13c-0.55,1.17-0.05,2.57,1.13,3.12
 C132.32,104.11,133.72,103.61,134.27,102.43"/>
<path d="M126.7,114.59c0.77-1.04,0.56-2.51-0.48-3.28s-2.51-0.56-3.28,0.48c-0.77,1.04-0.56,2.51,0.48,3.28
 C124.46,115.84,125.92,115.63,126.7,114.59"/>
<path d="M116.84,124.98c0.97-0.86,1.05-2.34,0.19-3.31c-0.86-0.97-2.34-1.05-3.31-0.19c-0.97,0.86-1.05,2.34-0.19,3.31
 C114.39,125.75,115.87,125.84,116.84,124.98"/>
<path d="M105.09,133.17c1.12-0.65,1.5-2.08,0.86-3.2c-0.65-1.12-2.08-1.5-3.2-0.86c-1.12,0.65-1.5,2.08-0.86,3.2
 C102.53,133.43,103.97,133.82,105.09,133.17"/>
<path d="M91.94,138.85c1.24-0.41,1.92-1.76,1.5-3c-0.41-1.24-1.76-1.92-3-1.5c-1.24,0.41-1.92,1.76-1.5,3
 C89.35,138.6,90.69,139.27,91.94,138.85"/>
<path d="M77.9,141.72c1.29-0.15,2.2-1.32,2.05-2.6c-0.15-1.29-1.32-2.2-2.6-2.05c-1.29,0.15-2.2,1.32-2.05,2.6
 C75.45,140.95,76.61,141.87,77.9,141.72"/>
<path d="M63.58,141.72c1.29,0.11,2.42-0.85,2.53-2.14c0.11-1.29-0.85-2.42-2.14-2.53c-1.29-0.11-2.42,0.85-2.53,2.14
 C61.33,140.48,62.29,141.62,63.58,141.72"/>
<path d="M49.54,138.88c1.26,0.37,2.58-0.35,2.95-1.61c0.37-1.26-0.35-2.58-1.61-2.95c-1.26-0.37-2.58,0.35-2.95,1.61
 C47.56,137.19,48.28,138.51,49.54,138.88"/>
<path d="M36.38,133.21c1.14,0.61,2.56,0.18,3.17-0.97c0.61-1.14,0.18-2.56-0.97-3.17c-1.14-0.61-2.56-0.18-3.17,0.97
 C34.81,131.18,35.24,132.6,36.38,133.21"/>
<path d="M24.62,125.03c1,0.83,2.47,0.69,3.3-0.31c0.83-1,0.69-2.47-0.31-3.3c-1-0.83-2.47-0.69-3.3,0.31
 C23.49,122.73,23.62,124.21,24.62,125.03"/>
<path d="M14.75,114.66c0.81,1.01,2.29,1.17,3.29,0.36c1.01-0.81,1.17-2.29,0.36-3.29c-0.81-1.01-2.29-1.17-3.29-0.36
 C14.1,112.17,13.94,113.65,14.75,114.66"/>
<path d="M7.16,102.51c0.59,1.15,2,1.61,3.15,1.02c1.15-0.59,1.61-2,1.02-3.15c-0.59-1.15-2-1.61-3.15-1.02
 C7.03,99.94,6.57,101.36,7.16,102.51"/>
<path d="M2.18,89.08c0.35,1.25,1.64,1.98,2.89,1.63c1.25-0.35,1.98-1.64,1.63-2.89c-0.35-1.25-1.64-1.98-2.89-1.63
 C2.57,86.54,1.84,87.83,2.18,89.08"/>
<path d="M0.01,74.92c0.09,1.29,1.21,2.27,2.5,2.18c1.29-0.09,2.27-1.21,2.18-2.5c-0.09-1.29-1.21-2.27-2.5-2.18
 C0.89,72.51-0.08,73.63,0.01,74.92"/>
<path d="M0.72,60.62c-0.17,1.28,0.73,2.46,2.01,2.64c1.28,0.17,2.46-0.73,2.64-2.01c0.17-1.28-0.73-2.46-2.01-2.64
 C2.08,58.44,0.9,59.34,0.72,60.62"/>
<path d="M4.3,46.75c-0.43,1.22,0.21,2.56,1.44,2.99c1.22,0.43,2.56-0.21,2.99-1.44c0.43-1.22-0.21-2.56-1.44-2.99
 C6.07,44.89,4.73,45.53,4.3,46.75"/>
<path d="M10.6,33.89c-0.67,1.11-0.3,2.55,0.81,3.21c1.11,0.67,2.55,0.3,3.21-0.81c0.67-1.11,0.3-2.55-0.81-3.21
 C12.71,32.42,11.27,32.78,10.6,33.89"/>
<path d="M19.34,22.54c-0.89,0.97-0.82,2.47,0.14,3.36c0.97,0.89,2.47,0.82,3.36-0.14c0.89-0.97,0.82-2.47-0.14-3.36
 C21.73,21.51,20.23,21.57,19.34,22.54"/>
<path d="M30.18,13.16c-1.08,0.78-1.33,2.3-0.55,3.38c0.78,1.08,2.3,1.33,3.38,0.55c1.08-0.78,1.33-2.3,0.55-3.38
 C32.78,12.62,31.27,12.38,30.18,13.16"/>
<path d="M42.68,6.12c-1.26,0.57-1.82,2.05-1.25,3.3c0.57,1.26,2.05,1.82,3.3,1.25c1.26-0.57,1.82-2.05,1.25-3.3
 C45.42,6.12,43.94,5.56,42.68,6.12"/>
<path d="M56.34,1.67c-1.43,0.32-2.33,1.74-2.01,3.17c0.32,1.43,1.74,2.33,3.17,2.01c1.43-0.32,2.33-1.74,2.01-3.17
 C59.19,2.25,57.77,1.35,56.34,1.67"/>
</g>
</svg>`